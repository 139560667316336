<template>
  <div id="section4">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p class="text-justify">
            {{$t("payText")}}
          </p>
          <br>
          <div>
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza" >
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "An exclusive  rate tailored to your needs.",
    "payText": "At UniversalPay we adapt all our services to your needs. And the rates, too.",
    "payBtn": "SIGN UP NOW"
  },
  "es":{
    "payTitulo": "Una tarifa única a medida de tus necesidades.",
    "payText": "En UniversalPay adaptamos todos nuestros servicios a tus necesidades. Y las tarifas, también.",
    "payBtn": "CONTRATA AHORA"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payUrl: "page_pay5"
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>
