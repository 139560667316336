<template>
  <div id="section1">
    <div class="fondoSilver">
      <b-img :src="payImg" class="imgContS5" style="height: 30vh"/>      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/img_header_pagosonline.jpg")
    }
  }
};
</script>
