<template>
  <div class="solucion2">
    <div class="wrapper">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from "@/components/pay/solucion/page2/Section1";
import Section2 from "@/components/pay/solucion/page2/Section2";
import Section3 from "@/components/pay/solucion/page2/Section3";
import Section4 from "@/components/pay/solucion/page2/Section4";
import Section5 from "@/components/pay/solucion/page2/Section5";

export default {
  name: "solucion2",
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
  },
  head: {
    title: {
      inner: "PAGOS ONLINE"
    },
  },
  mounted() {
    this.$i18n.locale = "es";
  }
};
</script>