<template>
  <div id="section5">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p>
            {{$t("payText")}}
          </p>
          <br>
            <b-row align-h="center">
              <b-col md="4" sm="12" class="text-center"
                v-for="(item, idx) in payList" :key="idx">
                <div class="text-center">
                  <b-img :src="payListI[idx].btnImg"/>
                  <br>
                  <h4>
                    {{item.txt}}
                  </h4>
                  <h3>
                    {{item.titulo}}
                  </h3>
                  <p>
                    {{item.texto}}
                  </p>
                </div>
              </b-col>
            </b-row>
          <br />
          <div class="text-center">
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza" >
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Discover our forms of integration.",
    "payText": "Do you have a company and a website? All you need is to integrate a digital payment method for your customers. We have the ones that best suit your technical and business needs.",
    "payBtn": "GET TO KNOW MORE",
    "payList": [
      {
        "titulo": "Integration Form"
        , "txt": "Hosted Payments"
        , "texto": "The most common and easiest way to accept online payments through the shopping cart or payment button."
      },
      {
        "titulo": "Iframe Form Integration"
        , "txt": "Hosted Payments"
        , "texto": "Simplify the payment process by fully implementing it in your website, instead of redirecting to Snap*."
      },
      {
        "titulo": "Server or Direct Integration"
        , "txt": "Commerce Web Services"
        , "texto": "The most complete solution that allows you to design your own payment form and create a personalized experience."
      }
    ]
  },
  "es":{
    "payTitulo": "Descubre nuestras formas de integración.",
    "payText": "¿Tienes una empresa y una página web? Lo único que te falta es integrar una forma de pago digital para tus clientes. Nosotros tenemos las que mejor se adaptan a tus necesidades técnicas y de negocio.",
    "payBtn": "INFÓRMATE",
    "payList": [
      {
        "titulo": "Integración de Formulario"
        , "txt": "Hosted Payments"
        , "texto": "La forma más común y sencilla de aceptar pagos online a través del carro de la compra o del botón de pago."
      },
      {
        "titulo": "Integración de Formulario Inframe"
        , "txt": "Hosted Payments"
        , "texto": "Simplifica el proceso de pago implementándolo por completo en tu página web, en lugar de redirigir a Snap*."
      },
      {
        "titulo": "Integración de Servidor o Directa"
        , "txt": "Commerce Web Services"
        , "texto": "La solución más completa que te permite diseñar tu propio formulario de pago y crear una experiencia personalizada."
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payUrl: "page_pay3.4",
      payListI: [
        {
          btnImg: require("@/assets/images/pay/icons_desarrolladores_01.png")
        },
        {
          btnImg: require("@/assets/images/pay/icons_desarrolladores_02.png")
        },
        {
          btnImg: require("@/assets/images/pay/icons_desarrolladores_03.png")
        }
      ]
    };
  },
  computed: {
    payList() {
      return this.$t("payList");
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>