<template>
  <div id="section2">
    <div class="fondoWhite" >
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p>
            {{$t("payText")}}
          </p>
          <br>
            <b-row align-h="center">
              <b-col md="3" sm="12" class="text-center"
                v-for="(item, idx) in payList" :key="idx">
                <div class="text-center">
                  <a
                    class="btn paybtnIcon"
                  >
                    <font-awesome-icon :icon="payListI[idx].btnImg" class="socialIconPay" />
                  </a>
                  <br>
                  <h3>
                    {{item.titulo}}
                  </h3>
                  <p>
                    {{item.texto}}
                  </p>
                </div>
                <br>
              </b-col>
            </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Open your business to the whole world due to online payments.",
    "payText": "Internet has no borders. And with our online payment platforms, your business will not have them either. With all the security and efficiency.",
    "payList": [
      {
        "titulo": "International Trade"
        , "texto": "Accept more than 130 different currencies and liquidate in 3 of them."
      },
      {
        "titulo": "Safely"
        , "texto": "With Snap* you make sure you comply with the PCI DSS standards without any effort."
      },
      {
        "titulo": "PCI DSS compliance"
        , "texto": "We avoid making you certify PCI DSS as your customers enter their sensitive data directly into our systems."
      },
      {
        "titulo": "Without changing banks"
        , "texto": "Sell in any country without creating a new checking account or going to a bank branch to register."
      },
      {
        "titulo": "On all channels"
        , "texto": "Accept payments how and where you want: in-store, online, mobile, e-mail, phone, Tablet, in-app."
      },
      {
        "titulo": "Alternative cards and payment methods"
        , "texto": "Your customers can pay you with any bank card and other alternative payment methods."
      },
      {
        "titulo": "Pre-authorizations"
        , "texto": "You can validate your customer`s cards before executing the payment."
      },
      {
        "titulo": "Repeating and delayed payments"
        , "texto": "Your customers can manage repeating payments and securely subscribe to your service."
      }
    ]
  },
  "es":{
    "payTitulo": "Abre tu negocio al mundo entero gracias a los pagos online.",
    "payText": "Internet no tiene fronteras. Y con nuestras plataformas de pagos online, tu negocio tampoco las tendrás. Con toda la seguridad y eficiencia.",
    "payList": [
      {
        "titulo": "Comercio internacional"
        , "texto": "Acepta más de 130 divisas diferentes y liquida en 3 de ellas."
      },
      {
        "titulo": "Con seguridad"
        , "texto": "Con Snap* te aseguras cumplir con la normativa PCI DSS sin esfuerzo."
      },
      {
        "titulo": "Cumplimiento PCI DSS"
        , "texto": "Evitamos que tengas que certificarte PCI DSS ya que tus clientes introducen sus datos sensibles directamente en nuestros sistemas."
      },
      {
        "titulo": "Sin cambiar de banco"
        , "texto": "Vende en cualquier país sin necesidad de crear una cuenta corriente nueva ni pasar por una sucursal bancaria para darte de alta."
      },
      {
        "titulo": "En todos los canales"
        , "texto": "Acepta pagos cómo y dónde quieras: in-store, online, móvil, e-mail, teléfono, Tablet, in-app."
      },
      {
        "titulo": "Tarjetas y medios de pago alternativos"
        , "texto": "Tus clientes pueden pagarte con cualquier tarjeta bancaria y otros medios de pago alternativos."
      },
      {
        "titulo": "Pre-autorizaciones"
        , "texto": "Podrás validar las tarjetas de tus clientes antes de ejecutar un pago de forma definitiva."
      },
      {
        "titulo": "Pagos recurrentes y aplazados"
        , "texto": "Tus clientes podrán gestionar pagos recurrentes y suscribirse de forma segura a tus servicio."
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payListI: [
        {
          btnImg: ['fas','globe']
        },
        {
          btnImg: ['fas','lock']
        },
        {
          btnImg: ['fas','clock']
        },
        {
          btnImg: ['fas','university']
        },
        {
          btnImg: ['fas','mobile-alt']
        },
        {
          btnImg: ['fas','credit-card']
        },
        {
          btnImg: ['fas','check']
        },
        {
          btnImg: ['fas','cart-arrow-down']
        }
      ]
    };
  },
  computed: {
    payList() {
      return this.$t("payList");
    }
  }
};
</script>