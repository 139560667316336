<template>
  <div id="section3">
    <div class="fondoGris" >
      <b-container fluid>
        <b-container class="text-center">
          <br />
            <b-row align-h="center" align-v="center" v-responsive.md.lg.xl>
              <b-col md="5" class="text-right">
                <yt :idvideo="payYoutube" :key="payYoutube" :activo="false" />
              </b-col>
              <b-col md="6" class="text-left">
                <div class="d-flex justify-content-stard">
                  <b-img :src="payIcon"/>
                </div>
                <br>
                <h3>
                  {{$t("payTitulo")}}
                </h3>
                <br>
                <p>
                  {{$t("payText")}}
                </p>
                <br>
                <p class="text-right">
                  <span  style="font-size: 0.65em"
                  v-for="(item, idx) in payTxt" :key="idx">{{item}} <br></span>
                </p>
                <br />
              </b-col>
            </b-row>
            <b-row align-h="center" align-v="center" v-responsive.sm.xs>
              <b-col sm="12" class="text-center">
                <yt :idvideo="payYoutube" :key="payYoutube" :activo="false" />
              </b-col>
              <b-col sm="12" class="text-center">
                <div class="d-flex justify-content-center">
                  <b-img :src="payIcon"/>
                </div>
                <br>
                <h3>
                  {{$t("payTitulo")}}
                </h3>
                <br>
                <p>
                  {{$t("payText")}}
                </p>
                <br>
                <p class="text-center" style="font-size: 0.8em">
                  <span v-for="(item, idx) in payTxt" :key="idx">{{item}} <br></span>
                </p>
                <br />
              </b-col>
            </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Herramientas y toolkits pensados para desarrolladores."
    , "payText": "Vende más y de forma más segura en todo el mundo. Ofrece la mejor experiencia de pago digital por todos los canales: web, móvil, tablet y teléfono de forma homogénea y con una única integración."
    , "payTxt": [
      "Snap* es considerada la plataforma de pagos más innovadora en 2016, según los premios Digital Awardzz 2016.",
      "Este premio reconoce la labor de Snap* por su capacidad para dar soluciones integradas a sus clientes."
    ]
  },
  "es":{
    "payTitulo": "Herramientas y toolkits pensados para desarrolladores."
    , "payText": "Vende más y de forma más segura en todo el mundo. Ofrece la mejor experiencia de pago digital por todos los canales: web, móvil, tablet y teléfono de forma homogénea y con una única integración."
    , "payTxt": [
      "Snap* es considerada la plataforma de pagos más innovadora en 2016, según los premios Digital Awardzz 2016.",
      "Este premio reconoce la labor de Snap* por su capacidad para dar soluciones integradas a sus clientes."
    ]
  }
}
</i18n>

<script>
import yt from "@/components/utils/youtube";
export default {
  components: {
    yt
  },
  data() {
    return {
      payIcon: "https://www.universalpay.es/wp-content/uploads/Snap.png"
      , payYoutube: "nt0ekFtEumE"
    };
  },
  computed: {
    payTxt() {
      return this.$t("payTxt");
    }
  }
};
</script>